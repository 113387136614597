import React, { useState, useEffect } from 'react';
import '../../styles/main.css';
import { fetchUserRewardHistory, notifyVisitorEvent } from "../../api/action";
import paymeCoin from '../../assets/PayMeCoin.png';
import CalenderImg from '../../assets/calender.png';
import TimerImg from '../../assets/time.png';
import User from '../../assets/user.png'
import CopyImg from "../../assets/copyImg.png"
import ToastMsg from '../../common/ToastMsg';
import "react-toastify/dist/ReactToastify.css";
import mixpanel from 'mixpanel-browser';
import moment from 'moment';

const HistorySecDashboard = () => {
  const [rewardHistory, setRewardHistory] = useState([]);
  const [error, setError] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const token = localStorage.getItem('authToken');
const [transListCheck, setTransListCheck] = useState(0);
const [rewardDetailsLoaded, setRewardDetailsLoaded] = useState(localStorage.getItem('rewardDetailsLoaded') === 'true');


useEffect(() => {
  const handleStorageChange = () => {
    const rewardDetailsLoaded = localStorage.getItem('rewardDetailsLoaded') === 'true';

    if (token && rewardDetailsLoaded) {
      fetchUserRewardHistory()
        .then((data) => {
          setRewardHistory(data?.transactions.slice(0, 10));
          setTransListCheck(data?.transactions?.length > 10);
          
          localStorage.removeItem('rewardDetailsLoaded');
        })
        .catch((err) => {
          setError(err.message);
          
          localStorage.removeItem('rewardDetailsLoaded');
        });
    }
  };

  window.addEventListener('storageChanged', handleStorageChange);

  return () => {
    window.removeEventListener('storageChanged', handleStorageChange);
  };
}, [token]);




  const segregateHistoryByDate = (transactions) => {
    const today = new Date();
    const todayUTC = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const yesterdayUTC = new Date(Date.UTC(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate()));

    const todayTransactions = [];
    const yesterdayTransactions = [];
    const earlierTransactions = [];

    transactions.forEach((transaction) => {
      const txnDate = new Date(transaction.txn_date);

      if (txnDate.toDateString() === todayUTC.toDateString()) {
        todayTransactions.push(transaction);
      } else if (txnDate.toDateString() === yesterdayUTC.toDateString()) {
        yesterdayTransactions.push(transaction);
      } else {
        earlierTransactions.push(transaction);
      }
    });

    return { todayTransactions, yesterdayTransactions, earlierTransactions };
  };

  const { todayTransactions, yesterdayTransactions, earlierTransactions } = segregateHistoryByDate(rewardHistory);

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
  };
  
  const formatTime = (timeString) => {
    return moment(timeString, 'HH:mm:ss').format('hh:mm A');
  };
  
  const getTransactionDisplayName = (txnType, redeemedStatus) => {
    switch (txnType) {
      case "self_limit_assigned":
        return redeemedStatus === "IN_PROGRESS" ? "Loan Approved (Self) (In Progress)" : "Loan Approved (Self)";
      case "self_loan_disbursed":
        return "Loan Disbursed (Self)";
      case "referred_limit_assigned":
        return redeemedStatus === "IN_PROGRESS" ? "Loan Approved (In Progress)" : "Loan Approved";
      case "referred_loan_disbursed":
        return "Loan Disbursed";
      case "redeemed":
        return redeemedStatus === "IN_PROGRESS" ? "Redeemed (In Progress)" : "Redeemed";
      case "registered":
        return "Registered"; 
      default:
        return txnType;
    }
  };
  
  const viewallmixpanel = () =>{


    const payload = {
      event_name: "Interacted_PERP_Dashboard",
      event_body: {
        "Platform": "m-site",
        "Clicked on": "View All",
      },
    };
    
    notifyVisitorEvent(payload)
      .then((response) => {
        console.log("NotifyVisitor Event Sent:", response);
      })
      .catch((error) => {
        console.error("Error sending NotifyVisitor event:", error);
      });

      
    const mixpanelUserId =  localStorage.getItem("userIdMixpanel")
    mixpanel.identify(mixpanelUserId)
    mixpanel.track("Interacted_PERP_Dashboard", {
      "Clicked on": "View All",
    });
  }
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        
        
        setIsCopied(true);
        localStorage.setItem('copiedtoclipboard', isCopied);
        
        setTimeout(() => {
          setIsCopied(false);
          localStorage.removeItem('copiedtoclipboard');
        }, 2000);
      })
      .catch((err) => {

        ToastMsg('Failed to copy text to clipboard!', 'error');
        console.error('Failed to copy text: ', err);
        
      });
  };
  
  return (
    <>
    <div className="history-section">
    
      <div onClick={viewallmixpanel} className="history-header">
        <h2>History</h2>
      {transListCheck ?
        <a href="/view-all-transactions" className="view-all">View All</a>:""}
      </div>
      </div>

      {error && <p className="error-message">{error}</p>}

      {todayTransactions.length > 0 && (
  <div className="history-day-main">
    <h3 className='todayyest'>Today</h3>
    {todayTransactions.map((item, index) => {
      const transactionId = String(item.transactionn_id);
      const lastFourDigits = transactionId.length >= 4 ? transactionId.slice(-4).toUpperCase() : 'N/A';

      return (
        <React.Fragment key={index}>
        <div className="history-item mb-12">
          <div className="history-item-content">
            <div className="history-item-main">
              <p className="transaction-type history-trans-heading">
                {item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS" ? (
                  <>
                    Redeemed{' '}
                    <span className='paymecolournew inprog inprogress' style={{ color: 'rgba(255, 162, 0, 1)', marginLeft:"3px" }}>(In Progress)</span>
                  </>
                ) : item.txn_type === "redeemed" && item.redeemed_status === "FAILURE" ? (
                  <>
                    Redeemed{' '}
                    <span className='paymecolournew failure' style={{ color: 'red', marginLeft:"3px" }}>(Failed)</span>
                  </>
                ) : (
                  getTransactionDisplayName(item.txn_type, item.redeemed_status)
                )}
              </p>
      
              {item.txn_type !== "registered" && (
                <p
                  className={
                    item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS"
                      ? 'flex'
                      : item.txn_value > 0 
                      ? 'positive-amount flex'
                      : 'negative-amount flex'
                  }
                >
                  {item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS" ? (
                    <div className='inprogress'>
                      <img
                        className='paymecoinfixhistory1'
                        src={paymeCoin}
                        alt="Icon"
                        style={{ width: '16px', height: '16px', margin: '0 4px' }}
                      />
                      <span>{Math.abs(item.txn_value)}</span>
                    </div>
                  ) : item.txn_type === "redeemed" && item.redeemed_status === "FAILURE" ? (
                    <div style={{ color: "red" }}>
                      <img
                        className='paymecoinfixhistory1'
                        src={paymeCoin}
                        alt="Icon"
                        style={{ width: '16px', height: '16px', margin: '0 4px' }}
                      />
                      <span>{Math.abs(item.txn_value)}</span>
                    </div>
                  ) : item.txn_type === "redeemed" ? (
                    <div style={{ color: "red" }}>
                      -<img
                        className='paymecoinfixhistory1'
                        src={paymeCoin}
                        alt="Icon"
                        style={{ width: '16px', height: '16px', margin: '0 4px' }}
                      />
                      <span>{Math.abs(item.txn_value)}</span>
                    </div>
                  ) : (
                    <>
                      {item.txn_value > 0 ? '+' : '-'}
                      <img
                        className='paymecoinfixhistory1'
                        src={paymeCoin}
                        alt="Icon"
                        style={{ width: '16px', height: '16px', margin: '0 4px' }}
                      />
                      {Math.abs(item.txn_value)}
                    </>
                  )}
                </p>
              )}
            </div>
      
            <div className="history-item-details">
              <p className="transaction-details">
                {item?.name && (
                  <span className="date-icon">
                    <img className='hystoryminiicon' src={User} width={14} alt="User Icon" />
                    {(() => {
                      const parts = item.name.split(" ");
                      const firstWord = parts[0];
                      const secondWordFirstLetter = parts[1]?.charAt(0);
      
                      const capitalizedFirstWord = firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
                      const capitalizedSecondWordFirstLetter = secondWordFirstLetter ? secondWordFirstLetter.toUpperCase() : '';
      
                      return (
                        <span className='ml-4'>
                          {capitalizedFirstWord}
                          {capitalizedSecondWordFirstLetter ? ` ${capitalizedSecondWordFirstLetter}` : ''}
                        </span>
                      );
                    })()}
                  </span>
                )}
      
                <span className="date-icon">
                  <img className='hystoryminiicon' src={CalenderImg} width={14} alt="Calendar Icon" />
                </span>
                {formatDate(item.txn_date)}
      
                <span className="time-icon">
                  <img className='hystoryminiicon' src={TimerImg} width={14} alt="Timer Icon" />
                </span>
                {formatTime(item.txn_time.split('.')[0])}
      
                {!item?.name && (
                  <>
                    <span className='verticalline' >|</span>
                    <span className='mr-10'>
                      Txn. ID: xx{lastFourDigits}
                      <img
                        className='ml-5'
                        src={CopyImg}
                        width={14}
                        height={14}
                        alt="Copy"
                        onClick={() => copyToClipboard(transactionId)}
                      />
                    </span>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
      
      
      
      

      );
    })}
  </div>
)}



{yesterdayTransactions.length > 0 && (
  <div className="history-day-main">
    <h3 className='todayyest'>Yesterday</h3>
    {yesterdayTransactions.map((item, index) => {
      const transactionId = String(item.transactionn_id);
      const lastFourDigits = transactionId.length >= 4 ? transactionId.slice(-4).toUpperCase() : 'N/A';

      return (
        <React.Fragment key={index}>
        <div className="history-item mb-12">
          <div className="history-item-content">
            <div className="history-item-main">
              <p className="transaction-type history-trans-heading">
                {item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS" ? (
                  <>
                    Redeemed{' '}
                    <span className='paymecolournew inprog' style={{marginLeft:"3px"}} >(In Progress)</span>
                  </>
                ) : item.txn_type === "redeemed" && item.redeemed_status === "FAILURE" ? (
                  <>
                    Redeemed{' '}
                    <span className='paymecolournew' style={{color: "red", marginLeft:"3px"}}>(Failed)</span>
                  </>
                ) : (
                  getTransactionDisplayName(item.txn_type, item.redeemed_status)
                )}
              </p>
      
              {item.txn_type !== "registered" && (
                <p
                  className={
                    item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS"
                      ? 'flex' 
                      : item.txn_type === "redeemed" && item.redeemed_status === "FAILURE"
                      ? 'negative-amount flex'
                      : item.txn_value > 0 
                      ? 'positive-amount flex' 
                      : 'negative-amount flex'
                  }
                >
                  {item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS" ? (
                    <div className='inprogress'>
                      <img
                        className='paymecoinfixhistory1'
                        src={paymeCoin}
                        alt="Icon"
                        style={{ width: '16px', height: '16px', margin: '0 4px' }}
                      />
                      <span>{Math.abs(item.txn_value)}</span>
                    </div>
                  ) : item.txn_type === "redeemed" && item.redeemed_status === "FAILURE" ? (
                    <div style={{ color: "red" }}>
                      <img
                        className='paymecoinfixhistory1'
                        src={paymeCoin}
                        alt="Icon"
                        style={{ width: '16px', height: '16px', margin: '0 4px' }}
                      />
                      <span>{Math.abs(item.txn_value)}</span>
                    </div>
                  ) : item.txn_type === "redeemed" ? (
                    <div style={{ color: "red" }}>
                      -<img
                        className='paymecoinfixhistory1'
                        src={paymeCoin}
                        alt="Icon"
                        style={{ width: '16px', height: '16px', margin: '0 4px' }}
                      />
                      <span>{Math.abs(item.txn_value)}</span>
                    </div>
                  ) : (
                    <>
                      {item.txn_value > 0 ? '+' : '-'}
                      <img
                        className='paymecoinfixhistory1'
                        src={paymeCoin}
                        alt="Icon"
                        style={{ width: '16px', height: '16px', margin: '0 4px' }}
                      />
                      {Math.abs(item.txn_value)}
                    </>
                  )}
                </p>
              )}
            </div>
      
            <div className="history-item-details">
              <p className="transaction-details">
                {item?.name && (
                  <span className="date-icon">
                    <img className='hystoryminiicon' src={User} width={14} alt="User Icon" />
                    {(() => {
                      const parts = item.name.split(" ");
                      const firstWord = parts[0];
                      const secondWordFirstLetter = parts[1]?.charAt(0);
      
                      const capitalizedFirstWord = firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
                      const capitalizedSecondWordFirstLetter = secondWordFirstLetter ? secondWordFirstLetter.toUpperCase() : '';
      
                      return (
                        <span className='ml-4'>
                          {capitalizedFirstWord}
                          {capitalizedSecondWordFirstLetter ? ` ${capitalizedSecondWordFirstLetter}` : ''}
                        </span>
                      );
                    })()}
                  </span>
                )}
      
                <span className="date-icon">
                  <img className='hystoryminiicon' src={CalenderImg} width={14} alt="Calendar Icon" />
                </span>
                {formatDate(item.txn_date)}
      
                <span className="time-icon">
                  <img className='hystoryminiicon' src={TimerImg} width={14} alt="Timer Icon" />
                </span>
                {formatTime(item.txn_time.split('.')[0])}
      
                {!item?.name && (
                  <>
                    <span className='verticalline'>|</span>
                    <span className='mr-10'>
                      Txn. ID: xx{lastFourDigits}
                      <img
                        className='ml-5'
                        src={CopyImg}
                        width={14}
                        height={14}
                        alt="Copy"
                        onClick={() => copyToClipboard(transactionId)}
                      />
                    </span>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
      
      );
    })}
  </div>
)}




{earlierTransactions.length > 0 && (
  <div className="history-day-main">
    <h3 className='todayyest'>Earlier</h3>
    {earlierTransactions.map((item, index) => {
      const transactionId = String(item.transactionn_id);
      const lastFourDigits = transactionId.length >= 4 ? transactionId.slice(-4).toUpperCase() : 'N/A';

      return (
        <>
          <div className="history-item" key={index}>
              <div className="history-item-content">
                <div className="history-item-main">
                  <p className="transaction-type history-trans-heading">
                    {item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS" ? (
                      <>
                        Redeemed <span style={{ marginLeft: "3px" }} className="paymecolournew inprog">(In Progress)</span>
                      </>
                    ) : item.txn_type === "redeemed" && item.redeemed_status === "FAILED" ? (
                      <>
                        Redeemed <span style={{ marginLeft: "3px", color: "red" }} className="paymecolournew failed">(Failed)</span>
                      </>
                    ) : (
                      getTransactionDisplayName(item.txn_type, item.redeemed_status)
                    )}
                  </p>
            
                  {item.txn_type !== "registered" && (
                    <p className={item.txn_value > 0 ? 'positive-amount flex' : 'negative-amount flex'}>
                      {item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS" ? (
                        <div className="inprogress">
                          <img
                            src={paymeCoin}
                            alt="Icon"
                            style={{ width: '16px', height: '16px', margin: '6px 4px' }}
                          />
                          <span>{Math.abs(item.txn_value)}</span>
                        </div>
                      ) : item.txn_type === "redeemed" && item.redeemed_status === "FAILED" ? (
                        <div style={{ color: "red" }}>
                          -<img
                            src={paymeCoin}
                            alt="Icon"
                            style={{ width: '16px', height: '16px', margin: '6px 4px' }}
                          />
                          <span>{Math.abs(item.txn_value)}</span>
                        </div>
                      ) : item.txn_type === "redeemed" ? (
                        <div style={{ color: "red" }}>
                          -<img
                            src={paymeCoin}
                            alt="Icon"
                            style={{ width: '16px', height: '16px', margin: '6px 4px' }}
                          />
                          <span>{Math.abs(item.txn_value)}</span>
                        </div>
                      ) : (
                        <>
                          {item.txn_value > 0 ? '+' : '-'}
                          <img
                            src={paymeCoin}
                            alt="Icon"
                            style={{ width: '16px', height: '16px', margin: '6px 4px' }}
                          />
                          {Math.abs(item.txn_value)}
                        </>
                      )}
                    </p>
                  )}
                </div>
            
                <div className="history-item-details">
                  <p className="transaction-details earliat-transtions">
                    {item?.name && (
                      <span className="date-icon">
                        <img className="hystoryminiicon" src={User} width={14} alt="User Icon" />
                        {(() => {
                          const parts = item.name.split(" ");
                          const firstWord = parts[0];
                          const secondWordFirstLetter = parts[1]?.charAt(0);
                          
                          const capitalizedFirstWord = firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
                          const capitalizedSecondWordFirstLetter = secondWordFirstLetter ? secondWordFirstLetter.toUpperCase() : '';
                          
                          return (
                            <span className='ml-4'>
                              {capitalizedFirstWord}
                              {capitalizedSecondWordFirstLetter ? ` ${capitalizedSecondWordFirstLetter}` : ''}
                            </span>
                          );
                        })()}
                      </span>
                    )}
            
                    <span className="date-icon">
                      <img className="hystoryminiicon" src={CalenderImg} width={14} alt="Calendar Icon" />
                    </span>
                    {formatDate(item.txn_date)}
            
                    <span className="time-icon">
                      <img className="hystoryminiicon" src={TimerImg} width={14} alt="Timer Icon" />
                    </span>
                    {formatTime(item.txn_time.split('.')[0])}
            
                    {!item?.name && (
                      <>
                        <span style={{ marginLeft: "5px", marginRight: '5px' }}>|</span>
                        <span className="mr-10">
                          Txn. ID: xx{lastFourDigits}
                          <img
                            className="ml-5"
                            src={CopyImg}
                            width={14}
                            height={14}
                            alt="Copy"
                            onClick={() => copyToClipboard(transactionId)}
                          />
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>




      </>
      

      );
    })}
  </div>
)}


      {rewardHistory.length === 0 && !error && <p className='nohistory-found'>No history available</p>}
    
    </>
  );
};

export default HistorySecDashboard;
