import React, { useState } from 'react';
import '../../styles/main.css';
import holdingMoney from "../../assets/holdingmoney.png"
import up from "../../assets/up.png"
import Down from "../../assets/down.png"
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import {notifyVisitorEvent} from '../../api/action'

const HowItWorksSecDashboard = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleHowitWorks = () =>{
    setIsOpen(!isOpen)
  }
 const handleknowmoreredirect = ()=>{
  const payload = {
    event_name: "Interacted_PERP_Dashboard",
    event_body: {
      "Platform": "m-site",
      "Clicked on": "Know More",
    },
  };
  
  notifyVisitorEvent(payload)
    .then((response) => {
      console.log("NotifyVisitor Event Sent:", response);
    })
    .catch((error) => {
      console.error("Error sending NotifyVisitor event:", error);
    });

    
  const mixpanelUserId =  localStorage.getItem("userIdMixpanel")
  mixpanel.identify(mixpanelUserId)
  mixpanel.track("Interacted_PERP_Dashboard", {
    "Clicked on": "Know More",
  });
  navigate('/how-it-works');
 }

 
  return (
    <div>

    <div className="how-it-works">
      <div onClick={handleHowitWorks} className='flex-row-center-spacebtn mr-20'>
      <button >
        How it Works? 
      </button>
      {!isOpen ? 
      <img src={up} width={13.31} height={7}/>
      :
      <img src={Down} width={13.31} height={7}/>
       }
      </div>
      
    </div>
    {isOpen && (
      <div className='flex-col-cen'>
      <div className="how-it-works-content how-it-works-sec flex ">
        <img className='mr-10' src={holdingMoney} width={56} height={56} />
        <div>
        <p className='moreloanmorecoin'>More Loans, More Coins</p>
        <p className='mt-2'>
          Earn PayMe Coins by referring someone to PayMe. You and your referral get 
          <span className='paymecolour font-600'> 400 coins</span> when their credit limit is approved and <span className='paymecolour font-600'>600 coins</span> when their loan is disbursed.
        </p>
        </div>
      </div>
      <span onClick={handleknowmoreredirect} className='paymecolour underline knowmorefont'>Know more</span>
      </div>
    )}
    
    </div>
  );
};

export default HowItWorksSecDashboard;
