import React, { useState, useEffect } from 'react';
import {fetchUserReferLink, notifyVisitorEvent} from "../../api/action"
import ToastMsg from '../../common/ToastMsg';
import "react-toastify/dist/ReactToastify.css";
import mixpanel from 'mixpanel-browser';
import { Loader } from '../../common/Loader';
const ReferNowSectionAll = () => {
  const [error, setError] = useState(null);
  const [copiedError, setCopiedError] = useState(null);
  const [inprogressError, setInprogressError] = useState(null);
  
  const [referLinkData, setReferLinkData] = useState(null);
  const [claimError, setClaimError] = useState(false);
  const [ClaimErrormsg, setClaimErrormsg] = useState(null);
  const [loader, setLoader] = useState(false);

  
  
  
  
 

  useEffect(() => {
    const payload = {
      event_name: "Interacted_PERP_Dashboard",
      event_body: {
        "Platform": "m-site",
        "Clicked on": "Claim now",
      },
    };
    
    notifyVisitorEvent(payload)
      .then((response) => {
        console.log("NotifyVisitor Event Sent:", response);
      })
      .catch((error) => {
        console.error("Error sending NotifyVisitor event:", error);
      });

      
    const mixpanelUserId =  localStorage.getItem("userIdMixpanel")
    mixpanel.identify(mixpanelUserId)
    mixpanel.track("Interacted_PERP_Dashboard", {
      "Clicked on": "Claim now",
    });
    // handleReferLink()
    const checkError = () => {
      const storedError = localStorage.getItem('error');
      const copiedError = localStorage.getItem('copiedtoclipboard')
      const inprogressError = localStorage.getItem('inprogressError')
      const ClaimError = localStorage.getItem('claim')
      const ClaimErrormsg = localStorage.getItem('claimerror')
      setError(storedError);
      setCopiedError(copiedError)
      setInprogressError(inprogressError)
      setClaimError(ClaimError)
      setClaimErrormsg(ClaimErrormsg)
    };

    checkError();

    const intervalId = setInterval(checkError, 1000);

    
    
    return () => clearInterval(intervalId);
  }, []); 

  const tncmixpanel = ()=>{
    const mixpanelUserId =  localStorage.getItem("userIdMixpanel")
    mixpanel.identify(mixpanelUserId)
    mixpanel.track("Interacted_PERP_Dashboard", {
      "Clicked on": "T&C",
    });
  }
  function openNativeShare(referLinkData) {
    
    if (navigator.share) {
        const referralLink = referLinkData; 
        
        
        navigator.share({
            title: 'Check out PaymeIndia!',
            text: `Hey, I’m using PayMe for instant, hassle-free personal loans. Use this link to download the app: ${referralLink}`,
        }).then(() => {
            console.log('Thanks for sharing!');
        }).catch((error) => {
          setLoader(false)
            console.error('Error sharing:', error);
        });
    } else {
        alert('Your browser does not support the native share feature.');
    }
}

  return (
    <>
    
    
      <div className="refer-now-section-all">
   


      { inprogressError && 
      <div className="error-message">
        <span className="icon">!</span>
        <span className="message-text">Your claim request is already in process.</span>
      </div>}

      { copiedError && 
      <div className="error-message-copied">
        {/* <span className="icon">!</span> */}
        <p className="copied-notification copiedtext">Copied to clipboard</p>
      </div>}

      
        
        { error && 
      <div className="error-message">
        <span className="icon">!</span>
        <span className="message-text">Minimum <span className='paymecolour'>1000 Coins</span>  required to claim</span>
      </div>}
    <div className='referNowSection'>
{/*       
      <button onClick={() => openNativeShare(referLinkData?.referral_link)} className="refer-btn">Refer Now</button>
      <span onClick={tncmixpanel} className='paymecolour termsandcondition'>
  <a  href="https://www.paymeindia.in/terms/" target="_blank" rel="noopener noreferrer">
    Terms & Conditions
  </a>
</span> */}


    </div>
    </div>
    </>
  );
};

export default ReferNowSectionAll;
